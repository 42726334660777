import { usePermissions, useTranslate } from '../../customHooks';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardHeader, CardBody, Sheet, SheetBody } from "@panwds/react-ui";
import { PANWDSForm } from '../../components/PANWDSElements';
import { FormControl } from '@material-ui/core';
import { Button as PANWDSButton } from "@panwds/react-ui";
import { FirewallGeneralInfo, SecurityPolicies, EndpointManagement } from './Components/Create';
import SaveButton from '../../components/Buttons/SaveButton';
import { useCreateFirewallMutation } from '../../redux/services/firewalls-service'
import { toast } from '../../components';
import { useFilteredPermissions } from '../../customHooks/useFilteredPermissions';
import CircularLoader from '../../components/CircularLoader/CircularLoader';

const useStyles = makeStyles((theme) => ({
    sidePanelForm: {
        height: 'calc(100% - 64px)',
        '& .ra-input ': {
            padding: '7px 0',
        }
    },
    toolbar: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "10px 0",
        alignItems: "baseline",
        width: '100%',
        zIndex: 1000, 
    },
    sheetBody: {
        padding: 0,
        height: 'calc(100vh - 64px)', 
    },
    cardWithMargin: {
        marginBottom: theme.spacing(2),
    },
}));

export const FirewallCreate = (props: any) => {
    const { close, refetchFirewalls } = props;
    const translate = useTranslate();
    const classes = useStyles();
    const [createFirewall, { isLoading }] = useCreateFirewallMutation();

    const { filteredDeleteProtectionOptions, globalFirewallAdminOption } = useFilteredPermissions();

    const initialValues = {
        DeletionProtection: globalFirewallAdminOption ? [globalFirewallAdminOption.value] : [],
        PolicyManaged: 'Rulestack', // Default value for PolicyManaged
        RuleStackName: 'Default Rulestack' // Default value for RuleStackName
    };

    const CreateFirewallToolbar = (toolbarProps: any) => {
        const classes = useStyles();
        return (
            <div className={classes.toolbar}>
                <PANWDSButton
                    size="md"
                    appearance="secondary"
                    // disabled={submitting}
                    onClick={() => close()}
                    dataMetrics="cloudngfw-vpcGroupName-create-cancel-button"
                >
                    Cancel
                </PANWDSButton>
                <SaveButton
                    appearance="primary"
                    size="md"
                    redirect={false}
                    submitOnEnter={true}
                    loading={isLoading}
                    disabled={isLoading}
                    dataMetrics="cloudngfw-vpcGroupName-create-save-button"
                    {...toolbarProps}
                />
            </div>
        );
    };

    const handleSubmit = async (values: any, form: any) => {
    const { FirewallName, Region, AllowListAccounts, PolicyManaged, RuleStackName, ...rest } = values;

    // Create the updated firewall details object
    const updatedFirewallDetails = {
        ...rest,
        ...(AllowListAccounts && { AllowListAccounts: Array.isArray(AllowListAccounts) ? AllowListAccounts : [AllowListAccounts] }),
        ...(FirewallName && { Tags: [{ Key: 'FirewallName', Value: FirewallName }] }),
        ...(PolicyManaged === "Rulestack" && RuleStackName !== "Default Rulestack" && { RuleStackName }),
    };

    try {
        const result = await createFirewall({
        newFirewallDetails: updatedFirewallDetails,
        region: Region,
        }).unwrap();

        // Ensure the FirewallId is correctly extracted from the result
        const firewallId = result?.FirewallId;

        if (!firewallId) {
            throw new Error('FirewallId is missing in the response');
          }

        const newFirewallData = {
        Firewall: {
            ...updatedFirewallDetails,
            FirewallId: firewallId,
            Region: Region,
        },
        Status: {
            FirewallStatus: "CREATING", // or the appropriate status from the result
        },
        };

        close(); // Close the sheet on successful creation
        // Pass the newly created firewall data to refetchFirewalls
        refetchFirewalls(firewallId, newFirewallData);
    } catch (error: any) {
        toast.error(`${error?.code}: ${error?.error}`, {toastId: "create-firewall"});
        }
    };

    return (
        <>
         <Sheet
            onClose={() => close()}
            title={translate(`resources.firewallsV2.create`)}
            isOpen
            showMask={true}
            position="lg"
        >
            <SheetBody className={classes.sheetBody}>
                <div className={classes.sidePanelForm} style={{ position: 'relative' }}>
                    <CircularLoader loading={isLoading} />
                    <PANWDSForm 
                        onSubmit={(values: any, form: any) => handleSubmit(values, form)} 
                        toolbar={<CreateFirewallToolbar />} 
                        requireStylingFooter={true} 
                        initialValues={initialValues}
                        >
                        <FormControl fullWidth>
                            <div className={classes.cardWithMargin}>
                                <Card>
                                    <CardHeader title={translate(`resources.firewallsV2.generalInfo`)} />
                                    <CardBody>
                                        <FirewallGeneralInfo 
                                            filteredDeleteProtectionOptions={filteredDeleteProtectionOptions}
                                            globalFirewallAdminOption={globalFirewallAdminOption}
                                        />
                                    </CardBody>
                                </Card>
                            </div>
                            <div className={classes.cardWithMargin}>
                                <Card>
                                    <CardHeader title={translate(`resources.firewallsV2.securityPolicies`)} />
                                    <CardBody>
                                        <SecurityPolicies />
                                    </CardBody>
                                </Card>
                            </div>
                            <Card>
                                <CardHeader title={translate(`resources.firewallsV2.endpointManagement`)} />
                                <CardBody>
                                    <EndpointManagement />
                                </CardBody>
                            </Card>
                        </FormControl>
                    </PANWDSForm>
                </div>
            </SheetBody>
        </Sheet>
    </>
    );
};