/**
 * FWaaS data types
 */
import * as DataModels from './FwaasDataModels';
import {Identifier, Record} from "../types";

/**
 * Response type for all CRUD methods in FwaasDataProvider
 */
export interface IFwaasApiResponse {
    data?: any;
    error?: IFwaasApiError;
    nextToken?: string;
    timestamp?: string
}

export interface IFwaasApiError {
    code : number,
    error : string | undefined
}

export interface IResourceFactory {
    /*
     * CUD resource
     *
     * Returns resource object that was created/updated/deleted as
     * IFwaasApiResponse.data and IFwaasApiResponse.error if anything
     * fails.
     *
     * Example : CreateNGFirewall, UpdateNGFirewall, DeleteNGFirewall
     */
    create(resource: string, payload: any) : Promise<IFwaasApiResponse>;
    update(resource: string, payload: any) : Promise<IFwaasApiResponse>;
    delete(resource: string, payload: any) : Promise<IFwaasApiResponse>;

    /*
     * LIST of resource, and DESCRIBE for all keys from LIST response
     *
     * Returns Array of resource objects (each from ListNGFirewalls response)
     * as IFwaasApiResponse.data and IFwaasApiResponse.error if anything fails.
     *
     * Example : ListNGFirewalls, wait for response
     *             DescribeNGFirewall for all entires in ListNGFirewalls response
     *               wait for all describe response and return.
     */
    listDetail(resource: string, payload: any) : Promise<IFwaasApiResponse>;

    /*
     * LIST resource, for brief info on resources
     *
     * Example : ListNGFirewalls
     */
    // list(resource: string, payload: any) : Promise<IFwaasApiResponse>;

    /*
     * Describe resource
     *
     * Returns resource object as IFwaasApiResponse.data and
     * IFwaasApiResponse.error if anything fails.
     *
     * Example : DescribeNGFirewall
     */
    // describe(resource: string, payload: any) : any;
}

export interface ISerializer {
    fromJSON(obj: any) : Object;
    toJSON(obj: Object) : any;
}

/**
 * FWaas data model class implementations
 */
export class User implements DataModels.UserInfo, ISerializer, Record {
    id: Identifier = 0;
    UserName?: string;
    FirstName?: string;
    LastName?: string;
    Permissions?: DataModels.ApiSaasRolePermission[];
    UserStatus?: DataModels.CognitoUserStatus;
    UpdateToken?: string;

    fromJSON(obj: any) : User {
        let user = obj as User;
        if (!user.id) { user.id = 0; }
        return user;
    }

    toJSON(obj: User) : any {
        return JSON.stringify(obj);
    }
}

export class Account implements DataModels.LinkAccountInfo, ISerializer, Record {
    id: Identifier = 0;
    AccountId?: string;
    CloudFormationTemplateURL?: string;
    OnboardingStatus?: DataModels.AccountTableEntryStatus;
    ExternalId?: string;
    ServiceAccountId?: string;
    SnsTopicArn?: string;

    fromJSON(obj: any) : Account {
        let account = obj as Account;
        if (!account.id) { account.id = 0; }
        return account;
    }
    toJSON(obj: Account) : any {
        return JSON.stringify(obj);
    }
}

export class Firewall implements DataModels.FirewallResource, ISerializer, Record {
    id: Identifier = 0;
    AccountId?: string;
    EndpointMode: "ServiceManaged" | "CustomerManaged" = "ServiceManaged";
    FirewallName: string = "";
    SubnetMappings: {
      [k: string]: unknown;
    }[] = [];
    Tags?: {
      [k: string]: unknown;
    }[];
    VpcId: string = "";
    AppIdVersion?: string;
    AutomaticUpgradeAppIdVersion?: boolean;
    Description?: string;
    GlobalRuleStackName?: string;
    RuleStackName?: string;

    fromJSON(obj: any) : Firewall {
        let fw = obj as Firewall;
        if (!fw.id) { fw.id = 0; }
        return fw;
    }

    toJSON(obj: Firewall) : any {
        return JSON.stringify(obj);
    }
}

export class Rule implements DataModels.Rule, ISerializer, Record {
    id: Identifier = 0;
    RuleName: string = "";
    Description?: string;
    Enabled?: boolean;
    Source?: DataModels.RuleSource;
    NegateSource?: boolean;
    Destination?: DataModels.RuleDestination;
    NegateDestination?: boolean;
    Applications?: string[];
    Category?: DataModels.UrlCategory;
    Protocol?: string;
    AuditComment?: string;
    Action?: "Allow" | "DenySilent" | "DenyResetServer" | "DenyResetBoth";
    Logging?: boolean;
    DecryptionRuleType?: "SSLOutboundInspection";
    Tags?: DataModels.Tag[];
    UpdateToken?: string;

    fromJSON(obj: any) : Rule {
        let rule = obj as Rule;
        if (!rule.id) { rule.id = 0; }
        return rule;
    }

    toJSON(obj: Rule) : any {
        return JSON.stringify(obj);
    }
}

export class RuleStack implements DataModels.RuleStack, ISerializer, Record {
    id: Identifier = 0;
    Scope?: "Global" | "Local" = "Global";
    MinAppIdVersion?: string;
    Profiles?: DataModels.RuleStackProfiles;
    Description?: string;
    Tags?: DataModels.Tag[];
    UpdateToken?: string;
    RuleStackName?: string;
    RuleStackState?: string;

    fromJSON(obj: any) : RuleStack {
        let rs = obj as RuleStack;
        if (!rs.id) { rs.id = 0; }
        return rs;
    }

    toJSON(obj: RuleStack) : any {
        return JSON.stringify(obj);
    }
}

export class CustomURLCategory implements DataModels.CustomURLCategory, ISerializer, Record {
    id: Identifier = 0;
    URLTargets: string[] = [];
    Description?: string;
    Action?: string;
    AuditComment?: string;
    UpdateToken?: string;

    fromJSON(obj: any) : CustomURLCategory {
        let rs = obj as CustomURLCategory;
        if (!rs.id) { rs.id = 0; }
        return rs;
    }

    toJSON(obj: CustomURLCategory) : any {
        return JSON.stringify(obj);
    }
}

export class FeedInfo implements DataModels.FeedDetails, ISerializer, Record {
    id: Identifier = 0;
    Description?: string;
    Certificate?: string;
    FeedURL?: string;
    Type?: "IP_LIST" | "URL_LIST";
    Frequency?: "HOURLY" | "DAILY";
    Time?: number;
    AuditComment?: string;

    fromJSON(obj: any) : FeedInfo {
        let f = obj as FeedInfo;
        if (!f.id) { f.id = 0; }
        return f;
    }

    toJSON(obj: FeedInfo) : any {
        return JSON.stringify(obj);
    }
}

export class PrefixListInfo implements DataModels.PrefixListDetails, ISerializer, Record {
    id: Identifier = 0;
    PrefixList: string[] = [];
    Description?: string;
    AuditComment?: string;

    fromJSON(obj: any) : PrefixListInfo {
        let p = obj as PrefixListInfo;
        if (!p.id) { p.id = 0; }
        return p;
    }

    toJSON(obj: PrefixListInfo) : any {
        return JSON.stringify(obj);
    }
}

export class FqdnListDetails implements DataModels.FqdnListDetails, ISerializer, Record {
    id: Identifier = 0;
    Description?: string;
    FqdnList: string[] = [];
    AuditComment?: string;
    UpdateToken?: string;

    fromJSON(obj: any) : FqdnListDetails {
        let f = obj as FqdnListDetails;
        if (!f.id) { f.id = 0; }
        return f;
    }

    toJSON(obj: FqdnListDetails) : any {
        return JSON.stringify(obj);
    }
}

export class CertificateInfo implements DataModels.CertificateDetails, ISerializer, Record {
    id: Identifier = 0;
    CertificateSelfSigned: boolean = false;
    CertificateSignerArn?: string;
    AuditComment?: string;

    fromJSON(obj: any) : CertificateInfo {
        let p = obj as CertificateInfo;
        if (!p.id) { p.id = 0; }
        return p;
    }

    toJSON(obj: CertificateInfo) : any {
        return JSON.stringify(obj);
    }
}
