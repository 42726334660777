import { PANTitle, toast } from '../../components';
import {PANWDSBreadcrumbs} from "../../components/PANWDSElements";
import { useParams, withRouter} from "react-router-dom";
import { useTranslate } from '../../customHooks';
import { Tab, TabList, TabPanel, Tabs } from '@panwds/react-ui';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    customActiveTabs: {
        '& div[data-testid*="tab-tab"][class*="active:tw-border-l"]': {
            borderLeftWidth: '4px',
            borderLeftColor: '#38A5FF',
        }
    },
  }));

const FirewallEdit = (props: any) => {
    const translate = useTranslate();
    const { firewallname } = useParams<{ firewallname: string }>();
    const [activeTabId, setActiveTabId] = useState<string>('tab1')
    const breadcrumbMapping = {
        ngfirewalls: `${translate('resources.firewallsV2.shortTitle')}`,
        [firewallname]: firewallname,
    };
    const classes = useStyles();

    return (
        <>
            <PANWDSBreadcrumbs
                mapping={breadcrumbMapping}
            />
            <PANTitle
                title={firewallname}
            />
            <PANTitle divider={false} />
            <div className='tw-p-4 tw-h-full'>
                <Tabs orientation="vertical" addClassName={classes.customActiveTabs}>
                    <TabList activeTabId={activeTabId} onActiveChange={nextTabId => setActiveTabId(nextTabId)}>
                        <Tab id="tab1">
                            {translate('resources.firewallsV2.overview')}
                        </Tab>
                        <Tab id="tab2">
                            {translate('resources.firewallsV2.rules')}
                        </Tab>
                        <Tab id="tab3">
                            {translate('resources.firewallsV2.generalInfo')}
                        </Tab>
                        <Tab id="tab4">
                            {translate('resources.firewallsV2.securityPolicies')}
                        </Tab>
                        <Tab id="tab5">
                            {translate('resources.firewallsV2.egressNat')}
                        </Tab>
                        <Tab id="tab6">
                            {translate('resources.firewallsV2.userId')}
                        </Tab>
                        <Tab id="tab7">
                            {translate('resources.firewallsV2.endpointManagement')}
                        </Tab>
                        <Tab id="tab8">
                            {translate('resources.firewallsV2.logAndMetrics')}
                        </Tab>
                        <Tab id="tab9">
                            {translate('resources.firewallsV2.decryption')}
                        </Tab>
                    </TabList>
                    <div className='tw-m-0 tw-flex-1 tw-bg-white tw-border-0 tw-border-l-2 tw-border-gray-300 tw-border-solid'>
                        <TabPanel activeTabId={activeTabId} forTabId="tab1">
                            Tab 1 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="tab2">
                            Tab 2 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="tab3">
                            Tab 3 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="tab4">
                            Tab 4 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="tab5">
                            Tab 5 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="tab6">
                            Tab 6 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="tab7">
                            Tab 7 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="tab8">
                            Tab 8 Content
                        </TabPanel>
                        <TabPanel addClassName='tw-flex-1 tw-bg-white' activeTabId={activeTabId} forTabId="tab9">
                            Tab 9 Content
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </>
    );
};
export default withRouter(FirewallEdit);
