/**
 * FWaaS constants file
 */

/**
 * Resources; These are the UI application URL extensions for Fwaas resources.
 *
 * Example -
 *  http://<fqdn>/#/users
 *  http://<fqdn>/#/accounts
 *  http://<fqdn>/#/firewalls
 */
export enum RESOURCE {
    // Management
    USER = "users",
    ACCOUNT = "accounts",
    ROLE = "role",
    SUBSCRIPTION = "subscription",
    TOKEN = "tokens",
    SUPPORT = "support",
    XACCOUNT_ROLES = "xaccountroles",
    PERMISSION_POLICIES = "permissionpolicies",
    CHECK_USER = "checkUser",
    USER_MIGRATION = "userMigration",

    // Configuration
    FIREWALL = "firewalls",
    RULESTACK = "ruleStacks",
    RULES = "rules",
    PREFIX = "prefix",
    FQDN = "fqdn", // CHECK correct name of resource fqdn | fqdnlists
    CATEGORY = "category",
    APPID = "appid",
    APPLICATIONS = "applications",
    COUNTRY = "countries",
    FQDNLIST = "fqdnlists",
    PREFIXLIST = "prefixlists",
    FEED = "feed",
    CERTIFICATE = "certificate",
    COUNTRIES = "countries",
    PREDEFINED = "predefinedUrlCategory",
    PREDEFINED_UPDATE = "predefinedUrlCategoryUpdate",
    PREDEFINED_OVERRIDE = "predefinedUrlCategoryOverride",
    LOGPROFILE = "logProfile",
    RULE_COUNTERS = "counters",
    RULE_COUNTERS_RESET = "counters_reset",

    // security services
    FILEBLOCKING = "fileBlocking",
    FILEBLOCKING_CUSTOM = "fileBlockingCustom",

    // tags endpoint for ngfirewall or rulestack
    TAGS = "tags",

    // settings endpoint
    SETTINGS = "settings",

    //billing endpoint
    BILLING = "billing",

    // inventory endpoint
    VPCS = "vpcs",
    VPCTAGS = "vpcTags",
    VPCTAGIPS = "vpcTagIPs",
    VPCGROUPS = "vpcGroups",
    VPCPREFIXTAGS = "vpcPrefixTags",

    PANORAMA = "panorama",
    CLOUDMANAGER = "cloudManager",
    INTEGRATIONS = "integrations",
    REGIONS = "regions",
    DEVICE_GROUPS = "dgs",
    DLP_LINKS = "dlpLinks"
};

/**
 * API URI end points - REST end point URI served by backend
 */
// Management end points
export const URI_USERS : string = "/mgmt/userrolemappings";
export const URI_ACCOUNTS : string = "/mgmt/linkaccounts";
export const URI_LINK_ACCOUNTS : string = "/mgmt/linkaccounts";
export const URI_XACCOUNT_ROLES : string = "/mgmt/xaccountroles";
export const URI_SUBSCRIPTION : string = "/mgmt/subscribe";
export const URI_TOKEN : string = "/mgmt/tokens";
export const URI_SUPPORT : string = "/mgmt/support";
export const URI_PERMISSION_POLICIES : string = "/mgmt/permissionpolicies";
export const URI_CHECK_USER : string = "/login/v1/checkuser";
export const URI_USER_MIGRATION : string = "/mgmt/usermigration";

// Configuration end points
export const URI_FIREWALLS : string = "/config/ngfirewalls";
export const URI_RULESTACKS : string = "/config/rulestacks";
export const URI_RULES : string = "/config/rulestacks/{ruleStackName}/rulelists/{ruleListName}";
export const URI_PREFIX : string = "/config/prefix"; // TODO confirm prefix url
// for describe, reset, refresh
export const URI_RULE_COUNTERS = "/config/rulestacks/{ruleStackName}/rulelists/{ruleListName}/priorities/{priority}/counters";
export const URI_RULE_COUNTERS_RESET = "/config/rulestacks/{ruleStackName}/rulelists/{ruleListName}/priorities/{priority}/reset";

// export const URI_CATEGORIES : string = "/config/urlcategories"; // TODO confirm categories url
export const URI_CATEGORIES : string = "/config/rulestacks/{ruleStackName}/urlcustomcategories"; // TODO confirm categories url
export const URI_FEED: string = "/config/rulestacks/{ruleStackName}/feeds"
export const URI_CERTIFICATE: string = "/config/rulestacks/{ruleStackName}/certificates"

//Inventory
export const VPCS :  string = "/config/monitoring/vpcs";
export const VPCTAGS :  string = "/config/monitoring/discoveredtags";
export const VPCTAGIPS: string = "/config/monitoring/discoveredtags/{name}"
export const VPCGROUPS :  string = "/config/monitoring/vpcgroups";
export const VPCPREFIXTAGS :  string = "/config/monitoring/discoveredips/{name}";

export const URI_APPID: string = "/config/appidversions";
export const URI_COUNTRIES: string = "/config/countries";
export const URI_PREFIXLIST: string = "/config/rulestacks/{ruleStackName}/prefixlists"
export const URI_FQDNLIST: string = "/config/rulestacks/{ruleStackName}/fqdnlists"

// confirm url
export const URI_PREDEFINED: string = "/config/urlcategories"
export const URI_PREDEFINED_UPDATE: string = "/config/rulestacks/{ruleStackName}/urlfilteringprofiles/custom/urlcategories/{name}/action";
export const URI_PREDEFINED_OVERRIDE: string = "/config/rulestacks/{ruleStackName}/urlfilteringprofiles/custom/urlcategories"

// security services
export const URI_FILEBLOCKING: string = "/config/filetypes"
export const URI_FILEBLOCKING_CUSTOM: string = "/config/rulestacks/{ruleStackName}/fileblockingprofiles/custom/filetypes"

// tags for rulestack or ngfirewall
export const URI_TAGS: string = "/config/{resourceType}/{resourceName}/tags"

// SETTINGS
export const URI_SETTINGS: string = "/mgmt/settings";

//BILLING
export const URI_BILLING: string = "/mgmt/billing";

export const URI_PANORAMA: string = "/mgmt/settings/panorama";
export const URI_CLOUDMANAGER: string = "/mgmt/settings/cm";
export const URI_INTEGRATIONS: string = "/mgmt/settings/links";
export const URI_DEVICE_GROUPS: string = "/mgmt/settings/panorama/dgs";

// REGIONS
export const URI_REGIONS: string = "/mgmt/settings/panorama/regions";
export const URI_REGIONS_AZLIST: string = "/mgmt/settings/tenant/regions";

// DLP
export const URI_DLP_LINKS: string = "/mgmt/settings/links";

// Miscellaneous
//export const API_STATUS : string = "success";
export const API_STATUS : number = 0;

export const APPID_VERSIONS : string = "/config/appidversions";

export const LOGPROFILE : string = '/config/ngfirewalls/{ngfirewallname}/logprofile';

export const SUPPORT_ENHANCED : string = "Enhanced";
export const SUPPORT_ESSENTIAL : string = "Essential";
export const SUPPORT_PREMIUM : string = "Premium";
export const SUPPORT_STANDARD : string = "Standard";

export const STATUS_PENDING : string = "Pending";
export const STATUS_SUCCESS : string = "Success";
export const STATUS_FAILED : string = "Failed";
