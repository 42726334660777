import { useHistory, useLocation, withRouter } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import { kebabCase, find } from "lodash";
import cx from "classnames";
import { makeStyles } from "@material-ui/core";
import * as DataTypes from "../../api/FwaasDataTypes";
import { InfoIcon, Toggle, Tooltip, Button as PANWDSButton, Link } from "@panwds/react-ui";
import { usePermissions } from "../../customHooks";
import {useTranslate} from '../../customHooks';
import Emitter from "../../eventEmitter";
import { PANTitle, toast } from "../../components";
import { PANWDSTabTable } from "../../components/PANWDSElements";
import { getVPCsFilters } from "./Utils/vpcFilters";
import { getTagsFilters } from "./Utils/tagsFilters";
import { dataProvider } from "../../dataProvider";
import { Failed, Success } from "../../images";
import { nameStyleCursor } from "../../layout/styles";
import { RouteUri } from "../../routeUri";
import AssociatedTagsIPsList from "./ips/AssociatedTagsIPsList";
import AssociatedTagsList from "./tags/AssociatedTagsList";
import _ from "lodash";
import VPCGroupList from "./vpcGroups/VPCGroupList";
import VpcGroupCreate from "./vpcGroups/VpcGroupCreate";
import VpcGroupEdit from "./vpcGroups/VpcGroupEdit";
import { getVpcEndpointDags } from '../../utils/GetUrlLinks';
import { Skeleton } from "@mui/material";

const useStyles = makeStyles(() => {
  return {
    appColumnStyles: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100px",
    },
    monitoredColumnStyles: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100px",
      display: "flex",
      alignItems: "center",
      "& span": {
        margin: "2px 5px 0 0",
      },
    },
    openInNewIcon: {
      "& svg": {
        width: "14px",
        height: "14px",
        marginLeft: "3px",
      },
    },
    flexStyles: {
      display: "flex",
    },
    deleteListStyles: {
      height: '50px',
      overflowY: 'scroll'
    }
  };
});

const InventoryList = (props: any) => {
  const { permissions, rawPermissions } = usePermissions();
  const translate = useTranslate();
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const nameClass = nameStyleCursor();
  const [loading, setLoading] = useState(true);
  const [vpcData, setVpcData] = useState<never[]>([]);
  const [tagsData, setTagsData] = useState<never[]>([]);
  const [queryParams, setQueryParams] = useState({});
  const [tagsQueryParams, setTagsQueryParams] = useState({});
  const [value, setValue] = useState("tab0");
  const [toggleTagId, setToggleTagId] = useState(false);
  const [toggleTagsIP, setToggleTagsIP] = useState(false);
  const [toggleVPCGroupsPanel, setToggleVPCGroupsPanel] = useState(false);
  const [groupedVPCsCount, setGroupedVPCsCount] = useState<any>({});
  const [vpcGroupData, setVpcGroupData] = useState<never[]>([]);
  const [toggleCreateVPCGroup, setToggleCreateVPCGroup] = useState(false);
  const [toggleEditVPCGroup, setToggleEditVPCGroup] = useState(false);
  const [editVpcRowData, setEditVpcRowData] = useState({});
  const [vpcFilters, setVpcFilters] = useState<any>(getVPCsFilters([], [], []));
  const [tagsFilters, setTagsFilters] =  useState<any>(getTagsFilters([], []));
  const [dateTimeVpc, setDateTimeVpc] = useState('');
  const [dateTimeTags, setDateTimeTags] = useState('');
  const [regionAccess, setRegionAccess] = useState(false);
  const [updating, setUpdating] = useState(false);
  const isPermitted = rawPermissions && find(rawPermissions, { Policy: "InventoryAdmin" });
  const isPermittedToggleRegionTenantAdmin = rawPermissions && find(rawPermissions, { Policy: "TenantAdmin" });

  const loadVPCData = async (needRefresh = false) => {
    if (!vpcData.length || needRefresh) {
      setLoading(true);
      setVpcData([]);
      setVpcFilters(getVPCsFilters([], [], []));
      Emitter.emit("regionLoadingData", true);
      location.pathname.indexOf("/vpcs") > 0 &&
        dataProvider
          .getList("vpcs")
          .then((response) => {
            if (response.data) {
              const formattedVpcData = response?.data?.flat();
              let monitoredData = [ ...new Set(formattedVpcData?.map((ele: any) =>  (ele.Monitored)))];
              monitoredData = monitoredData.filter((item: any) => item);
              // @ts-ignore
              setVpcData(formattedVpcData);
              let timestamp = response?.timestamp !== undefined ? translate(`panwdsTabTable.lastUpdated`) + ': ' + new Date(response?.timestamp).toLocaleString() : translate(`panwdsTabTable.lastUpdated`) + ': ' + 'N/A';
              setDateTimeVpc(timestamp);
              setVpcFilters(getVPCsFilters([ ...new Set(formattedVpcData?.map((ele: any) => ele.AccountId))], monitoredData, [ ...new Set(formattedVpcData?.map((ele: any) => ele.VpcGroup))]))
              isPermitted && loadVPCGroups();
            } else {
              toast.error(response?.error, { toastId: `vpc-list-error` });
            }
          })
          .catch((error: any) => {
            if (error?.noToast) {
              return;
            }
            setDateTimeVpc(translate(`panwdsTabTable.lastUpdated`) + ': ' + 'N/A');
            toast.error(error?.error?.error, { toastId: `vpc-list-error` });
          })
          .finally(() => {
            setLoading(false);
            Emitter.emit("regionLoadingData", false);
          });
    }
  };

  const loadTagsData = (needRefresh = false) => {
    if (!tagsData.length || needRefresh) {
      if (needRefresh) {
        setTagsData([]);
        setLoading(true);
        Emitter.emit("regionLoadingData", true);
      }
      dataProvider
        .getList("vpcTags")
        .then((response) => {
          if (response?.data) {
            const formattedTagsData = getTagsIPFormat(response?.data);
            // @ts-ignore
            setTagsData(formattedTagsData);
            let timestamp = response?.data?.LastUpdatedTimestamp !== undefined ? translate(`panwdsTabTable.lastUpdated`) + ': ' + new Date(response?.data?.LastUpdatedTimestamp).toLocaleString() : translate(`panwdsTabTable.lastUpdated`) + ': ' + 'N/A';
            setDateTimeTags(timestamp);
            setTagsFilters(getTagsFilters([ ...new Set(formattedTagsData?.map((ele: any) => ele.VpcId))], [ ...new Set(formattedTagsData?.map((ele: any) => ele.Account))]))
          } else {
            toast.error(response?.error, { toastId: `tags-list-error` });
          }
        })
        .catch((error: any) => {
          if (error?.noToast) {
            return;
          }
          setDateTimeTags(translate(`panwdsTabTable.lastUpdated`) + ': ' + 'N/A');
          toast.error(error?.error?.error, { toastId: `tags-list-error` });
        })
        .finally(() => {
          setLoading(false);
          Emitter.emit("regionLoadingData", false);
        });
    } else {
    }
  };

  const loadVPCGroups = () => {
    // if (!permissions?.ListRuleStacks) {
    //   if (permissions && !permissions?.ListRuleStacks) {
    //     // TODO toast is not showing, but if we add toast container it will show
    //     // many times until permissions is loaded, need to rethink this logic
    //     toast.warning(translate("permissions.cantView"));
    //   }
    //   return;
    // }
      dataProvider
        .getList("vpcGroups")
        .then((response) => {
          if (response?.data) {
            // @ts-ignore
            setVpcGroupData(response?.data);
          } else {
            toast.error(response?.error, { toastId: `vpc-group-list-error` });
          }
        })
        .catch((error: any) => {
          if (error?.noToast) {
            return;
          }
          toast.error(error?.error, { toastId: `vpc-group-list-error` });
        })
  };

  const getCombinedVPCGroupData = (responseData: any, vpcData: any, selectedItems: any) => {
    var vpcGroups =_.mapValues(_.groupBy(vpcData, 'VpcGroup'),
                    clist => clist?.map(group => _.omit(group, 'VpcGroup')));
    let combinedVpcGroup: any = [];
    Object.keys(vpcGroups).forEach(function (key) {
      key === "Default" ? combinedVpcGroup.unshift({key: key, name: key, value: `${key} (${vpcGroups[key]?.length})`, disabled: isValueSameAsSelected(key, selectedItems)})
                                       : combinedVpcGroup.push({key: key, name: key, value:`${key} (${vpcGroups[key]?.length})`, disabled: isValueSameAsSelected(key, selectedItems)});
    });
    const leftOvergroupsFromApi = responseData.filter((id1: any) => !combinedVpcGroup.some((id2: any) => id2.name === id1.Name));
    leftOvergroupsFromApi.forEach((vpcGroup: any) => {
      vpcGroup?.Name === "Default" ? combinedVpcGroup.unshift({key: vpcGroup.Name, name: vpcGroup.Name, value: `${vpcGroup.Name} (0)`, disabled: isValueSameAsSelected( vpcGroup.Name, selectedItems)})
      : combinedVpcGroup.push({key: vpcGroup.Name, name: vpcGroup.Name, value:`${vpcGroup.Name} (0)`, disabled: isValueSameAsSelected(vpcGroup.Name, selectedItems)});
    });
    return combinedVpcGroup;
  };

  const isValueSameAsSelected = (vpcgroupName: any, selectedItems: any) => {
    if (selectedItems.some((groupName: any) => groupName.VpcGroup !== vpcgroupName)) {
      return false;
    }
    return true;
  }

  const getRegionAccess = () => {
    setUpdating(true)
    dataProvider.describe("settings", '', { networkmonitoringconfig: true })
    .then(async (response: DataTypes.IFwaasApiResponse) => {
      if (response?.data) {
        setRegionAccess(response?.data?.EnableNetworkMonitoring)
      }
    })
    .catch((e: any) => {
      toast.error(e?.error?.error, { toastId: "settings-describe" });
    }).finally(() => {
      setUpdating(false);
    });
  }

  useEffect(() => {
    getRegionAccess();
    location.pathname.indexOf("/vpcs") > 0 && loadVPCData(false);
    location.pathname.indexOf("/tags") > 0 && loadTagsData(false);
  }, [location.pathname]);

  const handleRegionChange = (region: any) => {
    getRegionAccess();
    if(location.pathname.indexOf("/vpcs") > 0) {
      loadVPCData(true)
    } else {
      loadTagsData(true)
    }
  };

  const onRowClick = useCallback(
    (rowProps: any, event) => {
      let linkToOpen = getVpcEndpointDags(rowProps?.original?.VpcId);
      window.open(linkToOpen, '_blank');
    },
    [history, permissions]
  );

  const onTagNameClick = useCallback(
    (rowProps: any) => {
      setToggleTagId(true);
      setQueryParams({
        tagName: rowProps?.original?.TagName,
        account: rowProps?.original?.Account,
        vpcid: rowProps?.original?.VpcId,
      });
    },
    [permissions]
  );


  const backToTagIPsPanel = (row: any) => {
    setToggleTagId(true);
    setQueryParams({
      tagName: row.tagName,
      account: row.account,
      vpcid: row.vpcid,
    });
  };

  useEffect(() => {
    Emitter.on("regionChanged", handleRegionChange);
    return () => {
      Emitter.off("regionChanged", handleRegionChange);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.indexOf("/vpcs") > 0) {
      setValue("tab0");
    } else if (location.pathname.indexOf("/tags") > 0) {
      setValue("tab1");
    } else {
      history.replace(RouteUri.VPCsList);
    }
  }, []);

  const handleTabChange = (newTab: any) => {
    let newUrl = "";
    switch (newTab) {
      case "tab0":
        newUrl = RouteUri.VPCsList;
        break;
      case "tab1":
        newUrl = RouteUri.TagsList;
        break;
    }
    history.replace(newUrl);
  };

  const getVPCsColumns = () => [
    {
      accessor: "VpcId",
      Header: translate(`resources.inventory.vpc.vpc`),
      columnSize: 3,
      render: ({ row }: any) => {
        return (
          <span
            key={`vpc-record-${kebabCase(row?.original?.VpcId?.toLowerCase())}`}
            className={cx(nameClass.blueColor, classes.flexStyles)}
            data-test-id={`vpc-record-${kebabCase(
              row?.original?.VpcId?.toLowerCase()
            )}`}
            onClick={(evt) => onRowClick(row, evt)}
          >
            {row?.original?.VpcId}
            <span className={classes.openInNewIcon}>
              <Link
                dataMetrics="cloudngfw-inventory-open-new-page"
                external
              />
            </span>
          </span>
        );
      },
    },
    {
      accessor: "AccountId",
      Header: translate(`resources.inventory.vpc.awsaccount`),
      columnSize: 2.5,
      render: ({ row }: any) => {
        return (
          <span
            key={`vpc-record-${kebabCase(row?.original?.AccountId)}`}
            className={classes.appColumnStyles}
            data-test-id={`vpc-record-${kebabCase(row?.original?.AccountId)}`}
          >
            {row?.original?.AccountId}
          </span>
        );
      },
    },
    {
      accessor: "Monitored",
      Header: translate(`resources.inventory.vpc.vpcMonitoring`),
      columnSize: 3,
      render: ({ row }: any) => {
        return (
          <span
            key={`vpc-record-${kebabCase(row?.original?.Monitored)}`}
            className={classes.monitoredColumnStyles}
            data-test-id={`vpc-record-${kebabCase(row?.original?.Monitored)}`}
          >
            {row?.original?.Monitored === 'Success' && (
              <span>
                <img
                  data-metrics="cloudngfw-inventoryList-vpcLength-exclamationTriangle-img"
                  width="12px"
                  height="12px"
                  alt="VPCLength-exclamationTriangle"
                  src={Success}
                />
              </span>
            )}
             {row?.original?.Monitored === 'Failure' && (
              <span>
                <img
                  data-metrics="cloudngfw-inventoryList-vpcLength-exclamationTriangle-img"
                  width="12px"
                  height="12px"
                  alt="VPCLength-exclamationTriangle"
                  src={Failed}
                />
              </span>
            )}
            {row?.original?.Monitored}
          </span>
        );
      },
    },
    {
      accessor: "VpcGroup",
      Header: translate(`resources.inventory.vpc.vpcGroup`),
      columnSize: 3.5,
      render: ({ row }: any) => {
        return (
          <span
            key={`vpc-record-${kebabCase(row?.original?.VpcGroup)}`}
            className={classes.appColumnStyles}
            data-test-id={`vpc-record-${kebabCase(row?.original?.VpcGroup)}`}
          >
            {row?.original?.VpcGroup}
          </span>
        );
      },
    },
  ];

  const getTagsColumns = () => [
    {
      accessor: "TagName",
      Header: translate(`resources.inventory.tags.tagName`),
      columnSize: 4,
      render: ({ row }: any) => {
        return (
          <span
            key={`tags-record-${kebabCase(
              row?.original?.TagName?.toLowerCase()
            )}`}
            className={nameClass.blueColor}
            data-test-id={`tags-record-${kebabCase(
              row?.original?.TagName?.toLowerCase()
            )}`}
            onClick={() => onTagNameClick(row)}
          >
            {row?.original?.TagName}
          </span>
        );
      },
    },
    {
      accessor: "VpcId",
      Header: translate(`resources.inventory.vpc.vpc`),
      columnSize: 4,
      render: ({ row }: any) => {
        return (
          <span
            key={`tags-record-${kebabCase(row?.original?.VpcId)}`}
            className={classes.appColumnStyles}
            data-test-id={`tags-record-${kebabCase(row?.original?.VpcId)}`}
          >
            {row?.original?.VpcId}
          </span>
        );
      },
    },
    {
      accessor: "Account",
      Header: translate(`resources.inventory.vpc.awsaccount`),
      columnSize: 4,
      render: ({ row }: any) => {
        return (
          <span
            key={`tags-record-${kebabCase(row?.original?.Account)}`}
            className={classes.appColumnStyles}
            data-test-id={`tags-record-${kebabCase(row?.original?.Account)}`}
          >
            {row?.original?.Account}
          </span>
        );
      },
    },
  ];

  const getAssociatedTagsIPsList = () => {
    return (
      <AssociatedTagsIPsList
        isOpenTagIps={toggleTagId}
        setOpenTagIps={setToggleTagId}
        clickedDetails={queryParams}
        iPPrefixCallbackInvoked={(rowProps: any) =>
          onIPPrefixCallbackInvoked(rowProps)
        }
      />
    );
  };

  const getAssociatedTagsList = () => {
    return (
      <AssociatedTagsList
        isOpenTags={toggleTagsIP}
        setOpenTags={setToggleTagsIP}
        clickedIPDetails={tagsQueryParams}
        clickedTagIPDetails={queryParams}
        getTagIPsPanel={(rowProps: any) => backToTagIPsPanel(rowProps)}
      />
    );
  };

  const setCreatePanelOpen = () => {
    setToggleCreateVPCGroup(true);
  }

  const setEditViewPanel = (rowData: any) => {
    setToggleEditVPCGroup(true);
    setEditVpcRowData(rowData);
  }

  const setDeleteViewModal = (rowData: any) => {
    setToggleEditVPCGroup(true);
    setEditVpcRowData(rowData);
  }

  const getCreateVpcGroupPanel = () => {
    return  (
      <VpcGroupCreate
        isOpenTags={toggleCreateVPCGroup}
        setOpenTags={setToggleCreateVPCGroup}
        getManageVpcGroup={() => ManageVPCGroups(vpcData)}
      />
    );
  }

  const getEditVpcGroupPanel = () => {
    return  (
      <VpcGroupEdit
        isOpenTags={toggleEditVPCGroup}
        setOpenTags={setToggleEditVPCGroup}
        getManageVpcGroup={() => ManageVPCGroups(vpcData)}
        rowData={editVpcRowData}
        callBackVpcList={() => getEditVPCDelete()}
      />
    );
  }

  const  getVpcGroupList = () => {
    return (
      <VPCGroupList
        isOpenTags={toggleVPCGroupsPanel}
        setOpenTags={setToggleVPCGroupsPanel}
        groupedVPCs={groupedVPCsCount}
        createPanel={setCreatePanelOpen}
        getEditView={setEditViewPanel}
        getDeleteView={setDeleteViewModal}
        callBackVpcList={() => loadVPCData(true)}
        isCreateOpen={toggleCreateVPCGroup}
        isEditOpen={toggleEditVPCGroup}
      />
    );
  };

  const onIPPrefixCallbackInvoked = useCallback((rowProps: any) => {
    setToggleTagsIP(true);
    setTagsQueryParams({
      ipPrefix: rowProps?.original?.IpPrefix,
      account: rowProps?.original?.Account,
      vpcid: rowProps?.original?.VpcId
    });
  }, []);


  const getTagsIPFormat = (tagIPData: any) => {
    let formatVPCData: any = [];

   tagIPData?.Tags?.forEach((acc_vpc: any) => {
      for (const acc_vpc_data in acc_vpc) {
        if (acc_vpc.hasOwnProperty(acc_vpc_data)) {
            let bufs = acc_vpc_data?.split('#');
            const account = bufs[0];
            const vpcid = bufs[1];
            acc_vpc[acc_vpc_data]?.forEach((tag: any) => {
               formatVPCData.push({
                  TagName: tag,
                  Account: account,
                  VpcId: vpcid
            })
         })
        }
      }
   });
   return formatVPCData
  }

  const ManageVPCGroups = (vpcData: any) => {
    setToggleVPCGroupsPanel(true);
   // outputs an array of VPCs grouped under a vpcgroup
    var vpcGroups = _.mapValues(_.groupBy(vpcData, 'VpcGroup'),
                          clist => clist?.map(group => _.omit(group, 'VpcGroup')));
    setGroupedVPCsCount(vpcGroups)
  }

  const getEditVPCDelete = async () => {
    setToggleEditVPCGroup(false);
    setToggleVPCGroupsPanel(false);
    await loadVPCData(true);
  }

  const getGroupItems: any = (selectedItems: any) => {
    let combData: any = getCombinedVPCGroupData(vpcGroupData, vpcData, selectedItems);
    return combData;
  }

  const confirmChangeVpcGroup = (selItems: any, groupChange: any) => {
    if (selItems && Array.isArray(selItems)) {
      setLoading(true);
      setVpcData([]);
      const successItems: any = [];
      const failedItems: any = [];
      const commitedSuccess: any = [];
      let pendingCalls = 0;
      selItems.map((item: any) => {
        const vpcPayload: any = {
          AccountId: item?.AccountId,
          VpcId: item?.VpcId,
          VpcGroupEntry: {
            VpcGroup: groupChange?.toString()?.split('(')?.[0]
          }
        };
        dataProvider.update("vpcs", vpcPayload)
          .then(async (response: any) => {
            successItems.push(item);
            pendingCalls += 1;
            if (response.data) {
                    commitedSuccess.push(item);
                  } else {
                      toast.error(response?.error);
                  }
          })
          .catch((e: any) => {
            failedItems.push({ item, e })
          })
          .finally(() => {
            pendingCalls -= 1;
            DeleteAndNotify(selItems, successItems, commitedSuccess, failedItems, pendingCalls, groupChange)
          });
        return item;
      })
    }
  }

  const DeleteAndNotify = (selected: any, successItems: any, commitedSuccess: any, failedItems: any, pendingCalls: number, groupChange: any) => {
    if (failedItems.length + successItems.length === selected.length && pendingCalls === 0) { // request finished
      loadVPCData(true);
      if (commitedSuccess.length > 0) {
        let message = (
          <>
            <div>{`The following VPCs moved to the selected vpc group: ${groupChange}`}</div>
            <ul className={classes.deleteListStyles}>
              {commitedSuccess.map((item: any) => <li key={item?.VpcId}> - {item?.VpcId}</li>)}
            </ul>
          </>
        );
        toast.success(message);
      }
      if (failedItems.length > 0) {
        toast.error(<>
          Couldn't change the following VPCs to the vpc group(s):
          {<ul className={classes.deleteListStyles}>
            {failedItems.map(({ item, e }: any) => <li key={item?.VpcId}> - {item?.VpcId} ({e?.error})</li>)}
          </ul>}
        </>);
      }
    //   setTimeout(() =>  {
    //     setLoading(false)
    // }, 200);
    }
  };

  const vpcToolbarActionsCategorized: any[] = [];
  vpcToolbarActionsCategorized.push({
    title: translate(`resources.inventory.vpc.setVPCGroup`),
    // items: vpcGroupData,
    handleAction: (selItems: any, groupChange: any) => confirmChangeVpcGroup(selItems, groupChange),
    confirmModal: 'delete',
    dataMetrics: "cloudngfw-inventory-setVPCGroup-btn",
    getVPCGroupItems: (selectedItems: any) => getGroupItems(selectedItems),
  });
  vpcToolbarActionsCategorized.push({
    title: translate(`resources.inventory.vpc.manageVPCGroups`),
    actionsMap: [],
    dataMetrics: "cloudngfw-inventory-manageVPCs-btn",
    handleButtonAction: () => ManageVPCGroups(vpcData),
    type: "button",
    disabled: loading,
  });

  const tagsToolbarActionsCategorized: any[] = [];

  const isVPCsRoute = location.pathname.indexOf("/vpcs") > 0;

  const handleRegionAccessChange = (e: any) => {
    setUpdating(true);
    const payload = {
      EnableNetworkMonitoring: !regionAccess
    };
    dataProvider.update("settings", { payload })
      .then(async (response: any) => {
        location.pathname.indexOf("/vpcs") > 0 && loadVPCData(true);
        location.pathname.indexOf("/tags") > 0 && loadTagsData(true);
        setRegionAccess(response.data.EnableNetworkMonitoring);
      })
      .catch((e: any) => {
        toast.error(e?.error?.error, { toastId: "tokens-update" });
      }).finally(() => {
        setUpdating(false);
      })
  };

  return (
    <>
      <PANTitle
        region
        divider
        title={translate(`resources.inventory.name`)}
        paddingContainer="16px"
        children={[
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!updating ?
            <>
            {!regionAccess && <Tooltip data-metrics="cloudngfw-inventory-region-access" key={"region-access"} label={translate(`resources.inventory.regionAccessTooltip`)}>
                <span><InfoIcon className="tw-fill-current tw-text-gray-600 dark:tw-text-dark-bg" size="sm" /></span>
            </Tooltip>
            }
            <span style={{ padding: '0 8px 0 6px', fontWeight: '400', fontSize: '12px', color: '#333333' }}>{translate( regionAccess ? 'resources.tokens.values.enabled' : 'resources.tokens.values.disabled')}</span>
            <Toggle checked={regionAccess}
                    onClick={handleRegionAccessChange}
                    disabled={updating || !isPermittedToggleRegionTenantAdmin}
                    dataMetrics={"cloudngfw-inventory-edit-region-access"}
            />
            </>
            : <Skeleton variant="text" width="100px" height="15px" />
          }
        </div>
        ]}
      />
      <PANWDSTabTable
        permissions={permissions}
        columns={isVPCsRoute ? getVPCsColumns() : getTagsColumns()}
        gridData={isVPCsRoute ? vpcData : tagsData}
        loading={loading}
        title={
          isVPCsRoute
            ? `${translate(`resources.inventory.vpc.discoveredVPCs`)} (${
                vpcData?.length
              })`
            : `${translate(`resources.inventory.tags.discoveredTags`)} (${
                tagsData?.length
              })`
        }
        subtitle={loading ? undefined : isVPCsRoute ? dateTimeVpc : dateTimeTags}
        searchFilterRequired={true}
        showToolbar={true}
        dropDownActionsArray={
          isVPCsRoute
            ? vpcToolbarActionsCategorized
            : tagsToolbarActionsCategorized
        }
        showTileTitle
        emptyTitle={isVPCsRoute ? translate(
          `resources.inventory.emptyVpcsSubtitle`
        ) : translate(
          `resources.inventory.emptyTagsSubtitle`
        )}
        emptySubtitle={!regionAccess ? translate(
          `resources.inventory.regionAccessTooltip`
        ): ""}
        actions={!regionAccess && !updating && <PANWDSButton
          key='cloudngfw-action-button'
          appearance="primary"
          size="md"
          disabled={!isPermittedToggleRegionTenantAdmin}
          data-metrics="cloudngfw-button-region-enable-action"
          //@ts-ignore
          onClick={() => handleRegionAccessChange()}
        >
          {translate(`resources.inventory.enableRegion`)}
        </PANWDSButton>}
        singleSelect={false}
        multiSelect={isVPCsRoute && isPermitted !== undefined}
        dataTestId="vpcs-table"
        tableInnerContainerClassName={isVPCsRoute ? "vpcs-table" : "tags-table"}
        infiniteScroll={false}
        enablePagination={true}
        rowSize="standard"
        offsetTableHeight={330}
        filterBar={{
          filterBarRequired: true,
          filterConfig: isVPCsRoute
            ? vpcFilters
            : tagsFilters,
        }}
        tabs={{
          activeTabValue: value,
          tabsList: [
            {id: "tab0", tab: translate(`resources.inventory.vpc.discoveredVPCs`)},
            {id: "tab1", tab: translate(`resources.inventory.tags.discoveredTags`)},
          ],
          handleTabsChange: (newValue: any) => handleTabChange(newValue),
        }}
        // disableRow={(row : any) => {
        //   if (row && row?.row?.original?.VpcGroup === 'Default') {
        //     return true;
        //   }
        //   return false;
        // }}
      />
      {toggleTagId && getAssociatedTagsIPsList()}
      {toggleTagsIP && getAssociatedTagsList()}
      {toggleVPCGroupsPanel && getVpcGroupList()}
      {toggleCreateVPCGroup && getCreateVpcGroupPanel()}
      {toggleEditVPCGroup && getEditVpcGroupPanel()}
    </>
  );
};

export default withRouter(InventoryList);
