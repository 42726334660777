import * as EndPoints from "./FwaasUriEndPoints";
import { ApplicationConfigManager } from "../types";
import { ListFirewallRequest, ListRulesRequest } from "../interfaces/interfaces";
import configuration from "../config.json"

export const prepareHeaders = (headers: Headers) => {
    const token = localStorage.getItem('idToken');
    const xApiKey = ApplicationConfigManager.getInstance().getXApiKey();
    headers.append('Authorization', token || '');
    headers.append('x-api-key', xApiKey);
    headers.append('Content-Type', 'application/json');
    return headers;
};

export const buildError = (response: any, exception: any, options: any = {}) => {
    let errInfo;
    if (response) {
        errInfo = {
            code: response.status,
            error: response?.data?.Reason || response?.data?.ResponseStatus?.Reason || "Something went wrong!"
        };
    } else {
        let message = exception?.body?.ResponseStatus?.Reason || exception?.body?.message || exception?.message || exception?.error || exception?.json?.message;
        errInfo = {
            code: 0,
            error: message || exception || "Something went wrong!"
        };
    }

    return { ...errInfo, ...options };
};
  
export function getResourceURL(resource: EndPoints.RESOURCE, params?: { [key: string]: string }) {
    let BASE_URL: string = ApplicationConfigManager.getInstance().getAPIBaseUri();

    switch (resource) {
        case EndPoints.RESOURCE.FIREWALL:
            return BASE_URL + EndPoints.URI_FIREWALLS;
        case EndPoints.RESOURCE.REGIONS:
            return  BASE_URL + EndPoints.URI_REGIONS_AZLIST;
        case EndPoints.RESOURCE.PERMISSION_POLICIES:
            return BASE_URL + EndPoints.URI_PERMISSION_POLICIES;
        case EndPoints.RESOURCE.XACCOUNT_ROLES:
            return BASE_URL + EndPoints.URI_XACCOUNT_ROLES;
        case EndPoints.RESOURCE.RULES:
            let url = BASE_URL + EndPoints.URI_RULES;
            if (params) {
                Object.keys(params).forEach(key => {
                    url = url.replace(`{${key}}`, params[key]);
                });
            }
            return url;
        default:
            throw new Error("Unsupported resource type");
    }
}

export const API = {
    FETCH_FIREWALLS: (payload: ListFirewallRequest | undefined) => {

        let resourceUrl = `${configuration.v2ApiUrl}/v2/config/ngfirewalls`;

        if (payload && payload.nexttoken) {
            resourceUrl = resourceUrl + `?maxresults=${payload?.maxresults || 25}&describe=true&region=all&sandboxregion=${payload?.region}&nexttoken=${payload?.nexttoken}`;
        } else {
            resourceUrl = resourceUrl + `?maxresults=${payload?.maxresults || 25}&describe=true&region=all&sandboxregion=${payload?.region}`;
        }
        if (payload && payload.rulestackname) {
            resourceUrl += '&rulestackname=' + payload.globalrulestackname;
        }
        return resourceUrl;
    },
    FETCH_FIREWALL_BY_ID: (fwid: string, region: string) => {
         //TODO: Fix the api endpoint once the cross region is fixed.
        // return `${getResourceURL(EndPoints.RESOURCE.FIREWALL)}/{fwid}`;
        if (!fwid || typeof fwid !== 'string') {
            throw new Error("Invalid firewall ID");
        }
        if (!region || typeof region !== 'string') {
            throw new Error("Invalid region");
        }
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls/${encodeURIComponent(fwid)}?region=${encodeURIComponent(region)}`;
    },
    FETCH_REGIONS: (payload: any | undefined) => {
        let resourceUrl = getResourceURL(EndPoints.RESOURCE.REGIONS);
        if (payload && payload.region) {
            resourceUrl += `?region=${payload.region}`;
        }
        return resourceUrl;
    },
    FETCH_PERMISSION_POLICIES: () => {
        return getResourceURL(EndPoints.RESOURCE.PERMISSION_POLICIES);
    },
    FETCH_XACCOUNT_ROLES: () => {
        return getResourceURL(EndPoints.RESOURCE.XACCOUNT_ROLES);
    },
    FETCH_RULES: (payload: ListRulesRequest) => {
        const url = getResourceURL(EndPoints.RESOURCE.RULES, {
            ruleStackName: payload.ruleStackName,
            ruleListName: payload.ruleListName
        });
        const params: any = {
            maxresults: payload.MaxResults || 1000,
        };
        if (payload.NextToken) {
            params.NextToken = payload.NextToken;
        }
        return {
            url,
            params,
        };
    },
    CREATE_FIREWALL: () => {
        //TODO: Fix the api endpoint once the cross region is fixed.
        // return `${getResourceURL(EndPoints.RESOURCE.FIREWALL)}?region=${region}`;
        return `${configuration.v2ApiUrl}/v2/config/ngfirewalls`;
    },
};