import { useMemo } from "react";
import { DeleteIcon } from '@panwds/icons';
import { Button, Tooltip } from "@panwds/react-ui";
import { useCallback, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { usePermissions, useTranslate } from '../../customHooks';
import { Table } from "../../components/PANWDSElements/Table";
import { ColumnOptions, RowInstance } from "@panwds/react-table";
import { SelectFilter } from "@panwds/react-filters";
import { FirewallCreate } from "./FirewallCreate";
import { useGetFirewallsQuery } from "../../redux/services/firewalls-service";
import { ApplicationConfigManager, SupportedRegion } from "../../types";
import { toast } from "../../components";
import { FIREWALL_STATE_FILTERS, getFirewallStateDisplay, prepareData } from "./firewallsUtil";
import { find, get } from "lodash";
import { ListFirewallResponse } from "../../interfaces/interfaces";
import { RouteUri } from "../../routeUri";

type PanelType = "" | "createFirewall";

const FirewallList = () => {
  const translate = useTranslate();
  const [sidePanel, setSidePanel] = useState<PanelType>("");
  const [firewallsList, setFirewallsList] = useState<ListFirewallResponse[]>([]);
  const [nexttoken, setNextToken] = useState(null);
  const { permissions } = usePermissions();
  const history = useHistory();

  const regionItems = ApplicationConfigManager.getInstance().getSupportedRegions().map((region: SupportedRegion) => ({
    children: region.RegionDisplayName,
    value: region.RegionCode
  }));

  const { data: firewalls, error, isLoading, refetch } = useGetFirewallsQuery({ nexttoken, region: regionItems[0]?.value, maxresults: 10 }, {
    skip: nexttoken === undefined, // Skip the initial query if no nexttoken is provided
  });
  
  if (error) {
    toast.error(error?.error, {toastId: "support-describe"});
  }

  useEffect(() => {
    if (firewalls && firewalls.items) {
      setFirewallsList((prevItems) => [...prevItems, ...firewalls.items]);
      setNextToken(firewalls.nexttoken);
    }
  }, [firewalls]);

  const filterConfig = [
    {
      Component: SelectFilter,
      context: {
        enableMultiSelect: true,
        items: Object.entries(FIREWALL_STATE_FILTERS).map(([key, value]) => {
          return { value: key, children: value };
        })
      },
      label: translate('resources.firewallsV2.firewallStatus'),
      name: 'Status.FirewallStatus',
      static: true,
    },
    {
      Component: SelectFilter,
      context: {
        enableMultiSelect: true,
        items: regionItems,
      },
      label: translate('resources.firewallsV2.region'),
      name: 'Firewall.Region',
      static: true,
    },
    {
      Component: SelectFilter,
      context: {
        enableMultiSelect: true,
        items: [{
          value: 'Local Rulestack'
        },{
          value: 'Global Rulestack'
        },{
          value: 'Strata Cloud Manager'
        },{
          value: 'Panorama'
        }],
      },
      label: translate('resources.firewallsV2.policyManager'),
      name: 'Firewall.PolicyManager',
      static: true,
            comparator: (
        item: string,
        itemVal: string[],
        row: Record<string, unknown>
      ) => {
        return itemVal?.some(keyword => get(row, item).includes(keyword))
      },
    },
  ];

  const batchActions = () => ({
    rightControl: (
      <Button
        dataTestId="create-firewall"
        onClick={ () => openSidePanel()}
        appearance="primary"
        disabled={nexttoken !== undefined}
      >
        {translate(`resources.firewallsV2.create`)}
      </Button>
    ),
  });

  const onRowClick = useCallback((rowProps, event) => {
    if (!permissions?.DescribeFirewall) {
      return;
    }
    history.push(RouteUri.NGFirewallEdit
      .replace(":firewallname", rowProps.original.Firewall.FirewallId));
  }, [history, permissions]);

  const columns = useMemo<ColumnOptions<any>[]>(
    () => [
      {
        accessor: 'Firewall.FirewallId',
        Header: translate('resources.firewallsV2.firewallId'),
        minWidth: 250,
        Cell: ({ row }: { row: RowInstance<object> }) => {
          return <span
            onClick={(evt) => onRowClick(row, evt)}>
            {row?.original?.Firewall.FirewallId}
          </span>
        }
      },
      {
        accessor: 'Firewall.FirewallName',
        Header: translate('resources.firewallsV2.firewallName'),
        minWidth: 200,
      },
      {
        accessor: 'Status.FirewallStatus',
        Header: translate(`resources.firewallsV2.firewallStatus`),
        Cell: ({ value }: { value: string }) => {
          {return getFirewallStateDisplay(value)}
        }
      },
      {
        accessor: 'Firewall.Region',
        Header: translate(`resources.firewallsV2.region`),
        Cell: ({ value }: { value: string }) => {
          const region = find(regionItems, (region) => (region.value === value));
          return (region) ? region['children'] : value || '';
        }
      },
      {
        accessor: 'Firewall.PolicyManager',
        Header: translate(`resources.firewallsV2.policyManager`),
        minWidth: 250,
      },
      {
        accessor: 'Endpoints',
        Header: translate(`resources.firewallsV2.endpoints`),
        Cell: ({ row }: { row: RowInstance<object> }) => {
          return row?.original?.Status?.Attachments?.length || 0;
        }
      },
      {
        accessor: 'actions',
        Header: translate(`generic.actions`),
        Cell: () => {
          return (
            <>
              <Tooltip label={translate(`resources.firewallsV2.delete`)}>
                <button className="tw-mr-2 tw-border-none tw-bg-transparent">
                  <DeleteIcon
                    className="tw-text-zinc-400 dark:tw-text-gray-600 dark:group-hover:tw-text-gray-400 tw-cursor-pointer"
                    size="sm"
                    data-testid="deployment-list-delete-action"
                  />
                </button>
              </Tooltip>
            </>
          );
        },
        sticky: true,
      },
    ],
    []
  );
  const openSidePanel = useCallback(() => {
    setSidePanel('createFirewall')
  }, []);

  const refetchFirewalls = async (newFirewallId: any, newFirewallData?: ListFirewallResponse) => {
    // If newFirewallData is provided, add it to the top of the list
    if (newFirewallData) {
      setFirewallsList((prevFirewalls) => [newFirewallData, ...prevFirewalls]);
    } else {
      // Otherwise, refetch the list of firewalls
      const { data: firewalls } = await refetch();
      if (firewalls) {
        const newFirewall = firewalls.find((fw: any) => fw.Firewall.FirewallId === newFirewallId);
        const otherFirewalls = firewalls.filter((fw: any) => fw.Firewall.FirewallId !== newFirewallId);
        setFirewallsList([newFirewall, ...otherFirewalls]);
      }
    }
  };

  return (
    <>
      <div className="tw-overflow-table-container tw-flex tw-flex-col">
        <Table
          tableComponentData={prepareData(firewallsList)}
          tableComponentColumns={columns}
          tableComponentEmptyStateProps={{
            heading: translate('resources.firewallsV2.emptyFirewallsTable'),
          }}
          tableComponentFilterBar={{
            hideAddFilter: true,
            filterConfig: filterConfig,
          }}
          tableBatchAction={batchActions()}
          tableIsLoading={isLoading}
          tableisBackgroundFetching={nexttoken}
          tableTitle={translate('resources.firewallsV2.title')}
        />
      {sidePanel === 'createFirewall' && <FirewallCreate
        close={() => setSidePanel("")}
        cancel={() => {
            setSidePanel("");
        }}
        panoramaRecord={[]}
        cloudManagerRecord={[]}
        record={undefined}
        refetchFirewalls={refetchFirewalls} // Pass the refetch function as a prop
        />}
        </div>
    </>
  );
}

export default withRouter(FirewallList);