import { isEmpty, get } from "lodash";

export const getFilteredTableData = (tableProps, filters) => {
    let newGridData = tableProps.tableComponentData;
    if (isEmpty(newGridData)) {
        return;
    }
    Object.entries(filters).forEach(([filterProp, filterValues]: any) => {
        const filterValueExists =
            filterProp && typeof filterValues === "object"
                ? !isEmpty(filterValues)
                : filterValues;
        if (filterValueExists) {
            newGridData = newGridData.filter((row) => {
                const filterConfig =
                    tableProps?.tableComponentFilterBar?.filterConfig.find(
                        (item) => item.name === filterProp
                    );
                if (filterConfig?.comparator) {
                    return filterConfig.comparator(
                        filterProp,
                        filterValues,
                        row
                    );
                } else {
                    return filterValues.includes(get(row, filterProp));
                }
            });
        }
    });
    return newGridData;
};
